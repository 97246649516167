<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown">
    <v-card class="my-5 py-3 px-5">
      <v-card-title class="font-weight-bold">
        パスワードを忘れた際のお問い合わせ
      </v-card-title>

      <v-card-text class="ml-3">
        <p>送信後、システムからパスワード変更に関するメールが届きますので（迷惑メールフォルダ含め）ご確認ください。</p>
      </v-card-text>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="shopName"
                label="店舗名"
                required
                counter="50"
                :rules="[valiRules.required, valiRules.max50]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="email"
                label="ログインID（メールアドレス）"
                required
                counter="50"
                :rules="[valiRules.required, valiRules.email, valiRules.max50]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="submitForm"
            >
              送信
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>

      <!-- ローダー -->
      <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute top
      color="primary"
      ></v-progress-linear>
    </v-card>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import axios from 'axios'
import $literals from '@/literals.js'
import { API_ENDPOINT } from '@/literals.js'
import { CheckTokenError, ValidationRules } from '@/module.js'

export default {
  components: {
  },

  props: {
  },

  data() {
    return {
      shopName: '',
      email: '',
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
    };
  },

  computed: {
  },

  created() {
  },

  methods: {
    submitForm() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true

      const inquiryData = {
        'shopName': this.shopName,
        'email': this.email,
      }
      const payload = JSON.stringify(inquiryData)

      axios({
        ...{ method: 'POST' },
        ...{ url: API_ENDPOINT + '/password-inquiry/' },
        ...{ data: payload },
      })
      .then(() => {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successSendSubmit, open: true}}
      })
      .catch(error => {
        if (error.response.status === $literals.STATUS_CODE.dbErrNoRecordExist) {
          this.snackbar = {...{color:'error', message: "該当するログインIDが無いようです", open: true}}
        } else {
          CheckTokenError(error)
        }
      })
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
</style>
